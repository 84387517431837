<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { Link, useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Shared/ConfirmationModal.vue';

const props = defineProps({
    department: Object,
    events: Array,
    members: Array,
    scale: Object,
    eventDepartmentScale: Object,
});

const form = useForm({
    start_date: props.scale.start_date_gross,
    end_date: props.scale.end_date_gross,
});

const selectedEvent = ref(props.events[0] || null);
const selectedEvents = ref([]);

const isSaving = ref(false);

const initializeScales = () => {
    selectedEvents.value = props.events.map((event) => {
        const scale = props.eventDepartmentScale.find((eds) => eds.event.id === event.id);
        return {
            id: event.id,
            data: scale
                ? scale.members.map((member) => ({
                      id: member.departmentMember.id,
                      name: member.departmentMember.member.clean_name,
                      photo: member.departmentMember.member.identification_photo,
                      presenceStatus: member.presence_status || 'PRESENT',
                      replacement: member.replacement_id,
                      roles: member.departmentMember.functions,
                      default_role:
                          member.departmentFunction ||
                          member.departmentMember?.default_function ||
                          null,
                  }))
                : [],
        };
    });
};

const handleEventChange = (event) => {
    const eventId = event.target.value;
    const foundEvent = props.events.find((e) => e.id == eventId);

    if (foundEvent) {
        selectedEvent.value = foundEvent;
    }
};

const toggleMemberInEvent = (member) => {
    const event = selectedEvents.value.find((e) => e.id === selectedEvent.value.id);
    if (!event) return;

    const memberIndex = event.data.findIndex((m) => m.id === member.id);

    const previousState = [...event.data];

    if (memberIndex > -1) {
        event.data.splice(memberIndex, 1);
    } else {
        event.data.push({
            id: member.id,
            name: member.member.clean_name,
            photo: member.member.identification_photo,
            presenceStatus: 'PRESENT',
            replacement: null,
            roles: member.functions,
            default_role: member.default_function ?? null,
        });
    }

    update(previousState, event);
};

const updatePresenceStatus = (memberId, status) => {
    const event = selectedEvents.value.find((e) => e.id === selectedEvent.value.id);
    if (!event) return;

    const member = event.data.find((m) => m.id === memberId);
    if (member) {
        member.presenceStatus = status;

        if (status == 'CHANGED_SCHEDULE') {
            const substitutions = getAvailableReplacements(member);
            if (substitutions.length > 0) {
                member.replacement = substitutions[0].id;
                handleReplacementChange(member);
            }
        } else {
            update();
        }
    }
};

onMounted(() => {
    initializeScales();
});

const isMemberInEvent = (member) => {
    const event = selectedEvents.value.find((e) => e.id === selectedEvent.value.id);
    return event && event.data.some((m) => m.id === member.id);
};

const filteredEvents = computed(() => {
    return props.events.filter((event) => {
        const selectedEvent = selectedEvents.value.find((se) => se.id === event.id);
        return selectedEvent && selectedEvent.data.length > 0;
    });
});

const getAvailableReplacements = (member) => {
    const event = selectedEvents.value.find((e) => e.id === selectedEvent.value.id);
    return event?.data.filter((m) => m.id !== member.id) || [];
};

const update = (previousState, event) => {
    isSaving.value = true;

    validateMembersBeforeUpdate();

    const formattedEvents = {};

    selectedEvents.value.forEach((event) => {
        formattedEvents[event.id] = event.data.map((member) => ({
            id: member.id,
            presenceStatus: member.presenceStatus,
            replacement: member.presenceStatus === 'CHANGED_SCHEDULE' ? member.replacement : null,
            defaultRole: member.department_function_id ?? null,
        }));
    });

    form.transform((data) => ({
        ...data,
        events: formattedEvents,
    })).put(`/dashboard/departments/${props.department.id}/scales/${props.scale.id}`, {
        preserveState: true,
        preserveScroll: true,
        onError: () => {
            event.data = previousState;
        },
        onFinish: () => {
            isSaving.value = false;

            initializeScales();
        },
    });
};

const watchPresenceStatus = (member) => {
    const event = selectedEvents.value.find((e) => e.id === selectedEvent.value.id);
    const targetMember = event?.data.find((m) => m.id === member.id);

    if (targetMember?.presenceStatus === 'CHANGED_SCHEDULE' && !targetMember.replacement) {
        const replacements = getAvailableReplacements(member);
        if (replacements.length > 0) {
            targetMember.replacement = replacements[0].id;
        }
    }
};

watch(
    () => selectedEvents.value,
    (newValue) => {
        newValue.forEach((event) => {
            event.data.forEach((member) => {
                watchPresenceStatus(member);
            });
        });
    },
    { deep: true }
);

const handleReplacementChange = (member) => {
    const event = selectedEvents.value.find((e) => e.id === selectedEvent.value.id);
    if (!event) return;

    const targetMember = event.data.find((m) => m.id === member.id);
    if (targetMember) {
        targetMember.replacement = member.replacement;
        update();
    }
};

const handleFunctionChange = (member) => {
    const event = selectedEvents.value.find((e) => e.id === selectedEvent.value.id);
    if (!event) return;

    const targetMember = event.data.find((m) => m.id === member.id);
    if (targetMember) {
        targetMember.department_function_id = member.default_role.department_function_id;
        update();
    }
};

const scaleEditModalCloseButton = ref(null);

const destroy = () => {
    scaleEditModalCloseButton.value.click();
    router.delete(`/dashboard/departments/${props.scale.department_id}/scales/${props.scale.id}`);
};

const validateMembersBeforeUpdate = () => {
    selectedEvents.value.forEach((event) => {
        event.data.forEach((member) => {
            if (!member.department_function_id && member.default_role) {
                member.department_function_id = member.default_role.department_function_id;
            }
        });
    });
};
</script>

<template>
    <Dashboard page-title="Editar escala">
        <Breadcrumb
            :current-page-name="scale.month_year"
            :link-list="[
                {
                    previous_page_url: `departments/${department.id}/scales/${scale.id}`,
                    previous_page_name: 'Escalas',
                },
            ]"
        />

        <section id="edit-scale">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-start">
                <div class="col-6">
                    <h4 class="font-bold m-0 d-flex align-items-center">
                        Editar escala

                        <div class="d-inline ms-3" v-if="isSaving">
                            <font-awesome-icon
                                class="rotate-icon fs-6 me-2"
                                :icon="['fas', 'rotate']"
                            />
                            <span class="fs-12 font-bold" v-show="isSaving">Salvando...</span>
                        </div>
                        <div class="d-inline ms-3" v-else>
                            <font-awesome-icon
                                class="fs-5 text-success me-2"
                                :icon="['fas', 'circle-check']"
                            />
                        </div>
                    </h4>
                </div>
                <div class="col-6">
                    <div class="d-flex justify-content-end align-items-center">
                        <a
                            data-bs-toggle="modal"
                            data-bs-target="#scaleEditModal"
                            href="#"
                            class="btn btn-danger btn-add me-2 d-inline"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                            Deletar
                        </a>
                    </div>
                </div>
            </div>

            <hr class="mx-2" />

            <div
                class="alert alert-warning font-semibold fs-12 alert-dismissible fade show mb-3"
                role="alert"
                v-if="form.errors.events"
            >
                {{ form.errors.events }}
                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                ></button>
            </div>

            <div class="row">
                <div class="col-12 members-listing">
                    <div class="row">
                        <div
                            v-for="member in members"
                            :key="member.id"
                            class="col-md-2 col-6 col-sm-4 col-xxl-1 mb-2"
                        >
                            <div
                                class="card-simple card p-2 d-flex align-items-center justify-content-center"
                                :class="{
                                    'bg-primary bg-opacity-10 border border-primary':
                                        isMemberInEvent(member),
                                }"
                                @click="toggleMemberInEvent(member)"
                                style="cursor: pointer"
                            >
                                <img
                                    class="me-2 rounded-circle border border-info"
                                    width="30px"
                                    :src="member.member.identification_photo"
                                    alt="Foto de {{ member.member.clean_name }}"
                                />
                                <span class="font-bold mt-2 fs-12">
                                    {{ member.member.clean_name.split(' ')[0] }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <hr class="mx-2" />

                <div class="col-12">
                    <label for="event-select" class="form-label font-semibold">
                        Selecione o evento:
                    </label>
                    <select id="event-select" class="form-select" @change="handleEventChange">
                        <option v-for="event in events" :key="event.id" :value="event.id">
                            {{ event.schedule.name }} - {{ event.start_time }}
                        </option>
                    </select>
                </div>

                <div class="col-12 mt-3">
                    <div class="rounded-3 p-3 mb-3" style="border: 2px dashed #e5e5e5">
                        <h5 class="font-bold text-center fs-14 mb-4">
                            {{ selectedEvent.schedule.name }} - {{ selectedEvent.start_time }}
                        </h5>

                        <div class="row">
                            <div
                                v-for="member in selectedEvents.find(
                                    (event) => event.id === selectedEvent.id
                                )?.data || []"
                                :key="member.id"
                                class="col-12"
                            >
                                <div class="card-simple p-2 mb-2">
                                    <div
                                        class="d-flex row align-items-center justify-content-between p-2"
                                    >
                                        <div class="col-sm-6">
                                            <img
                                                class="me-2 rounded-circle"
                                                width="30px"
                                                :src="member.photo"
                                                alt="Foto de {{ member.name }}"
                                            />
                                            <span class="font-bold fs-12">
                                                {{ member.name }}
                                            </span>
                                        </div>

                                        <div class="col-sm-6 mt-3 mt-sm-0 text-end">
                                            <input
                                                type="radio"
                                                class="btn-check"
                                                :name="`options-base-${member.id}`"
                                                :id="`options-base-${member.id}-present`"
                                                autocomplete="off"
                                                @change="updatePresenceStatus(member.id, 'PRESENT')"
                                                :checked="member.presenceStatus === 'PRESENT'"
                                            />
                                            <label
                                                class="btn fs-10 btn-outline-success me-2"
                                                :for="`options-base-${member.id}-present`"
                                            >
                                                Presente
                                            </label>

                                            <input
                                                type="radio"
                                                class="btn-check"
                                                :name="`options-base-${member.id}`"
                                                :id="`options-base-${member.id}-absent`"
                                                autocomplete="off"
                                                @change="updatePresenceStatus(member.id, 'ABSENT')"
                                                :checked="member.presenceStatus === 'ABSENT'"
                                            />
                                            <label
                                                class="btn fs-10 btn-outline-danger me-2"
                                                :for="`options-base-${member.id}-absent`"
                                            >
                                                Ausente
                                            </label>

                                            <input
                                                type="radio"
                                                class="btn-check"
                                                :name="`options-base-${member.id}`"
                                                :id="`options-base-${member.id}-changed`"
                                                autocomplete="off"
                                                @change="
                                                    updatePresenceStatus(
                                                        member.id,
                                                        'CHANGED_SCHEDULE'
                                                    )
                                                "
                                                :checked="
                                                    member.presenceStatus === 'CHANGED_SCHEDULE'
                                                "
                                            />
                                            <label
                                                class="btn fs-10 btn-outline-primary"
                                                :for="`options-base-${member.id}-changed`"
                                            >
                                                Subtituído
                                            </label>
                                        </div>
                                    </div>

                                    <hr
                                        class="mb-0"
                                        v-if="member.presenceStatus === 'CHANGED_SCHEDULE'"
                                    />

                                    <div
                                        v-if="member.presenceStatus === 'CHANGED_SCHEDULE'"
                                        class="mt-2 row px-2 align-items-center"
                                    >
                                        <label
                                            for="staticEmail"
                                            class="col-sm-8 col-form-label font-semibold fs-12"
                                            >Selecionar substituto</label
                                        >
                                        <div class="col-sm-4">
                                            <select
                                                class="form-select"
                                                v-model="member.replacement"
                                                :key="`replacement-${member.id}`"
                                                @change="handleReplacementChange(member)"
                                            >
                                                <option value="">Selecione o substituto</option>
                                                <option
                                                    v-for="replacement in getAvailableReplacements(
                                                        member
                                                    )"
                                                    :key="replacement.id"
                                                    :value="replacement.id"
                                                >
                                                    {{ replacement.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <template v-if="member.roles.length">
                                        <hr />

                                        <div class="row px-2 align-items-center">
                                            <label
                                                for="roleSelect"
                                                class="col-sm-8 col-form-label font-semibold fs-12"
                                            >
                                                Selecionar função 
                                            </label>
                                            <div class="col-sm-4">
                                                <select
                                                    class="form-select"
                                                    v-model="
                                                        member.default_role.department_function_id
                                                    "
                                                    :key="`function-${member.id}`"
                                                    @change="handleFunctionChange(member)"
                                                    id="roleSelect"
                                                >
                                                    <option
                                                        v-for="role in member.roles"
                                                        :key="role.id"
                                                        :value="role.function.id"
                                                    >
                                                        {{ role.function.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <hr v-if="filteredEvents.length" />

                <div class="col-12" v-if="filteredEvents.length">
                    <h5 class="font-bold">Resumo das escalas</h5>
                    <div class="row p-2">
                        <div
                            v-for="(event, index) in filteredEvents"
                            :key="index"
                            class="col-md-6 col-xxl-3 mb-4 ps-0"
                        >
                            <div class="p-3 rounded-3" style="border: 2px dashed #ddd">
                                <h5 class="font-bold fs-14 text-center">
                                    {{ event.schedule.name }} - {{ event.start_time }}
                                </h5>
                                <ul class="list-group mt-sm-3 mt-2 p-3">
                                    <li
                                        v-for="member in selectedEvents.find(
                                            (e) => event.id === e.id
                                        )?.data"
                                        :key="member.id"
                                        class="fs-14 list-group-item d-flex align-items-center p-3"
                                    >
                                        <img
                                            class="me-2 rounded-circle"
                                            width="30px"
                                            :src="member.photo"
                                            alt="Foto de {{ member.name }}"
                                        />
                                        <span class="font-bold fs-12">
                                            <span class="me-3">
                                                {{ member.name }}
                                            </span>
                                            <span
                                                class="badge text-bg-primary px-3 py-2"
                                                v-if="member.default_role?.name"
                                            >
                                                {{ member.default_role.name }}
                                            </span>
                                            <span
                                                v-if="member.replacement"
                                                class="text-primary ms-2"
                                            >
                                                <font-awesome-icon
                                                    class="rotate-icon me-2"
                                                    :icon="['fas', 'rotate']"
                                                />
                                                (Trocou)
                                            </span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div class="col-md-6">
                    <label for="start_date" class="form-label font-semibold">
                        Data de início
                    </label>

                    <input
                        id="start_date"
                        v-model="form.start_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                        placeholder=""
                        @change="update"
                        required
                    />
                    <small v-if="form.errors.start_date" class="text-danger mt-3">
                        {{ form.errors.start_date }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="end_date" class="form-label font-semibold"> Data de fim </label>

                    <input
                        id="end_date"
                        v-model="form.end_date"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                        placeholder=""
                        @change="update"
                        required
                    />
                    <small v-if="form.errors.end_date" class="text-danger mt-3">
                        {{ form.errors.end_date }}
                    </small>
                </div>

                <ConfirmationModal
                    modal_id="scaleEditModal"
                    modal_title="Confirmação de remoção do registro"
                >
                    <template v-slot:modal-text>
                        Você tem certeza que deseja excluir o registro?
                    </template>
                    <template v-slot:modal-footer>
                        <button
                            type="button"
                            ref="scaleEditModalCloseButton"
                            class="btn btn-secondary text-white"
                            data-bs-dismiss="modal"
                        >
                            Voltar
                        </button>
                        <button @click="destroy" type="button" class="btn btn-danger text-white">
                            Deletar registro
                        </button>
                    </template>
                </ConfirmationModal>
            </div>
        </section>
    </Dashboard>
</template>
